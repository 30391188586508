import React from 'react'
import styled from '@emotion/styled'
// import Flex, { FlexItem } from 'styled-flex-component'
import { ExpandMore } from '@material-ui/icons'
import breakpoints from '../styles/breakpoints'

const Flex = styled.div`
    display: flex;

    &.full {
        width: 100%;
        height: 100%;
        flex-basis: 100%;
    }

    &.column {
        flex-direction: column;
    }

    &.alignCenter {
        align-items: center;
    }
    
    &.justifyBetween {
        justify-content: space-between;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }
`
const FlexItem = styled.div`
`

const Wrapper = styled.div`
    width: 100%
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    
    .sidebar {
        display: none;
        width: 15%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;


        &.sidebar-left {
            left: 0;
        }
        &.sidebar-right {
            right: 0;
        }

        @media ${breakpoints.xl} {
            display: flex;
        }        

        .vertical {
            writing-mode: vertical-rl;
            font-family: ${props => props.theme.fonts.serif};
            font-style: italic;
        }
        
        .vertical-border {
            width: .1rem;
            height: 4rem;
            background: ${props => props.theme.colors.primary};
            margin-bottom: 1.2rem;
        }

        .main {
            opacity: 0;
            transform: translateX(100px);
            transition: opacity ${props => props.theme.transitions.default}, transform ${props => props.theme.transitions.default};
            transition-delay: .5s;
            align-self: flex-end;
            width: 120px;
        }
    }

    .vert-bar {
        width: 100%;
        min-height: 100px;
        overflow: hidden;
     


        @media ${breakpoints.desktop} {
            height: 150px;
        }

    }

    .font-sans {
        font-family: ${props => props.theme.fonts.sans};
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .2rem;
        font-size: 1rem;
        margin-top: 1rem;
    }

    .primary {
        color: ${props => props.theme.colors.primary};
    }

    .content-container {
        width: 100%;
        margin: 0 auto;
        justify-content: space-between;

        &.full-width {
            width: 100%;
        }

        @media ${breakpoints.xl} {
            width: 70%;
            justify-content: space-between;
        }         
    }

    .content {
        padding-left: 10px;
        padding-right: 10px;

        &.full-width {
            padding-left: 0;            
            padding-right: 0;
            width: 100%;
        }
        
        @media ${breakpoints.xl} {
            padding: 10px 80px;
        }       
    }   
`;

const ContentWrapper = props => {

    const showScroll = props.showScroll;
    const fullWidthContent = props.fullWidthContent;
    let contentBottom;
    let fullWidth = "";

    if (showScroll) {
        contentBottom = <Flex  className="vert-bar scroll-down column center" >
                            <ExpandMore className="primary" />
                            <div className="font-sans">Scroll Down</div>
                        </Flex>;
    } else {
        contentBottom = <FlexItem className="vert-bar" />
    }
  

    if (fullWidthContent) {
        fullWidth = " full-width";
    }
    if (props.grow) {
        grow = " grow";
    }

  return (
    <Wrapper center className="content-wrapper">      
        <Flex className="sidebar sidebar-left" />  
        <Flex className={`content-container ${fullWidth} full column alignCenter`}>
        
            <FlexItem className="vert-bar top" />
            <div className={`content-container ${fullWidth}`}>
                {props.children}
            </div>                            
            {contentBottom}

        </Flex>

        <Flex className="column alignCenter justifyBetween sidebar sidebar-right">
            <FlexItem className="vert-bar top" />
            <Flex  className="center main">
                <Flex className="column center">
                    <div className="vertical-border" />
                    <FlexItem className="vertical">{props.verticalText}</FlexItem>
                </Flex>
            </Flex>
            <Flex className="center vert-bar" />              
        </Flex>
    </Wrapper>
  )
}

export default ContentWrapper
