import { css } from '@emotion/react'
import breakpoints from '../styles/breakpoints'
import theme from './theme'

const heading = css`
  font-family: ${theme.fonts.serif};
  font-weight: 400;
  line-height: 1.2;

  .bottom-border {
    width: 5rem;
    height: 1px;
    background: ${theme.colors.primary};
    margin-top: 10px;
  }

  &.center {
    @media ${breakpoints.tablet} {
      text-align: center;

      &,
      .bottom-border {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`

export default heading
