import React, { Component } from 'react'
import Layout from '../components/Layout'
import ReactFullpage from '@fullpage/react-fullpage'
import ScreenHome from '../components/screens/ScreenHome'
import ScreenAbout from '../components/screens/ScreenAbout'
import ScreenWhoWeAre from '../components/screens/ScreenWhoWeAre'
import ScreenWhatWeDo from '../components/screens/ScreenWhatWeDo'
import ScreenBackground from '../components/screens/ScreenBackground'
import ScreenContact from '../components/screens/ScreenContact'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      structure: [
        { anchor: 'home', title: 'Home' },
        { anchor: 'about', title: 'About Us' },
        { anchor: 'what-we-do', title: 'What We Do' },
        { anchor: 'background', title: 'Background' },
        { anchor: 'who-we-are', title: 'Who We Are' },
        { anchor: 'contact', title: 'Contact' },
      ],
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return false
  }

  render() {
    let anchors = this.state.structure.map(({ anchor }) => anchor)
    return (
      <Layout structure={this.state.structure}>
        {typeof window !== 'undefined' && (
          <ReactFullpage
            anchors={anchors}
            verticalCentered={false}
            slidesNavigation={true}
            controlArrows={true}
            scrollingSpeed={600}
            lazyLoading={false}
            responsiveHeight={600}
            easingcss3="ease-in-out"
            menu={'#sidebarMenu'}
            licenseKey={'FF7CD02E-4AEF4657-8B046FF7-B1E7A78B'}
            // pluginWrapper
            render={({ state, fullpageApi }) => {
              return (
                <ReactFullpage.Wrapper>
                  <ScreenHome />
                  <ScreenAbout />
                  <ScreenWhatWeDo />
                  <ScreenBackground />
                  <ScreenWhoWeAre />
                  <ScreenContact />
                </ReactFullpage.Wrapper>
              )
            }}
          />
        )}
      </Layout>
    )
  }
}

export default App
