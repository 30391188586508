import React from 'react'
import styled from '@emotion/styled'
import { Row } from 'react-flexbox-grid'

const StyledCenterRow = styled(Row)`
  justify-content: center;

  .center {
      text-align: center;
  }
`
const CenterRow = props => {
    return (
        <StyledCenterRow>
            { props.children }         
        </StyledCenterRow>
    )
}

export default CenterRow