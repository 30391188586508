import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 100%;
  overflow: hidden;
  background: none;
`
const SectionContainer = props => {

    const noBG = props.noBG;
    let noBGClass = "";

    if (noBG) {
        noBGClass = 'noBG';
    }

    return (
        <Container className={noBGClass}>
            { props.children }
        </Container>  
    )
}

export default SectionContainer