import React from 'react'
import styled from '@emotion/styled'

const Bullet = styled.div`
          height 16px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;          
`

const Outer = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid rgba(${(props) => props.theme.rgbs.primary}, 0);
  transition: border-color ${(props) => props.theme.transitions.default};
`

const Inner = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.bullet};
  border-radius: 50%;
  transition: background-color ${(props) => props.theme.transitions.default};
`

const SidebarMenuBullet = () => {
  return (
    <Bullet>
      <Outer className="outer">
        <Inner className="inner" />
      </Outer>
    </Bullet>
  )
}

export default SidebarMenuBullet
