import React from 'react'
import styled from '@emotion/styled'

const StyledVignette = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient( circle, transparent 50%, #111111 120%);    
`
const Vignette = props => {
    return (
        <StyledVignette />
    )
}

export default Vignette