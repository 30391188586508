import React from 'react'
import styled from '@emotion/styled'
import { fluidRange } from 'polished'
import breakpoints from '../styles/breakpoints'

const Body = styled.div`
  p {
    margin: 0 0 10px 0;
    line-height: 1.3;
    letter-spacing: 0;
   

    ${props => fluidRange(
      [{
        prop: 'font-size',
        fromSize: '16px',
        toSize: '18px',
      },
      {
        prop: 'margin-bottom',
        fromSize: '16px',
        toSize: '20px',
      }      
    ],
      '800px',
      '1000px',
    )}

    @media ${breakpoints.tablet} {
      letter-spacing: 1px;
    }  
    
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0;
  }


  ul {
    li {
      &,
      p {

        ${props => fluidRange(
          [{
            prop: 'font-size',
            fromSize: '14px',
            toSize: '18px',
          }],
          '800px',
          '1000px',
        )} 

      }
    }
  }


   > ul {
      > li {
        padding-left: 1.5em;
        position: relative;

        ${props => fluidRange(
          [{
            prop: 'margin-bottom',
            fromSize: '10px',
            toSize: '18px',
          }],
          '800px',
          '1000px',
        )}          

        &,
        p {

          ${props => fluidRange(
            [{
              prop: 'font-size',
              fromSize: '14px',
              toSize: '18px',
            }],
            '800px',
            '1000px',
          )}  

          font-family: ${props => props.theme.fonts.sans};
          font-weight: 300;
          line-height: 1;
          letter-spacing: .1rem;
          
          @media ${breakpoints.tablet} {
            line-height: 1.4;
          }              
          
          ${props => fluidRange(
            [{
              prop: 'font-size',
              fromSize: '16px',
              toSize: '18px',
            }],
            '800px',
            '1000px',
          )}          

        }

        p {
          margin: 0;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 1rem;
          height: 2px;    
          top: 10px;      
          left: 0;
          background: ${props => props.theme.colors.primary};

          ${props => fluidRange(
            [{
              prop: 'top',
              fromSize: '9px',
              toSize: '13px',
            }],
            '800px',
            '1000px',
          )} 

        }
      }
    }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }
`

const PageBody = props => {
  return (
    <Body
      dangerouslySetInnerHTML={{ __html: props.body.childMarkdownRemark.html }}
    />
  )
}

export default PageBody
