import React from 'react'
import styled from '@emotion/styled'
import breakpoints from '../styles/breakpoints'

const Section = styled.div`
  opacity: 0;
  transition: opacity ${(props) => props.theme.transitions.default};
  overflow: hidden;

  &.active {
    opacity: 1;
    .sidebar {
      .main {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .scroll-down,
    .fp-slidesNav {
      opacity: 1;
      transform: translateY(0);
    }

    .fp-controlArrow {
      opacity: 1;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .fp-slidesContainer {
    .fp-slide {
      height: auto;
      padding-left: 26px;
      padding-right: 26px;
      opacity: 0;
      transition: opacity ${(props) => props.theme.transitions.default};
      &.active {
        opacity: 1;
      }
    }
  }

  .fp-controlArrow {
    border: none;
    width: 75px !important;
    height: 65px;
    margin-top: -32.5px;
    background: url('/images/arrow.svg');
    z-index: 100;
    position: absolute;
    bottom: 37px;
    top: auto;
    opacity: 0;
    transition-delay: 0.5s !important;

    @media ${breakpoints.desktop} {
      top: 50%;
    }

    &:after {
      content: ' ';
      width: 65px;
      height: 65px;
      border-radius: 50%;
      position: absolute;
      right: -28.5px;
      top: -1px;
      overflow: hidden;
      border: 1px solid ${(props) => props.theme.colors.primary};
      box-shadow: inset 0 0 0 0px
          rgba(${(props) => props.theme.rgbs.primary}, 0.1),
        0 0 0 rgba(0, 0, 0, 0.1);
      transition: box-shadow ${(props) => props.theme.transitions.default},
        border-color ${(props) => props.theme.transitions.default};
    }

    &:hover {
      &:after {
        border-color: white;
        box-shadow: inset 0 0 0 33px
            rgba(${(props) => props.theme.rgbs.primary}, 0.2),
          0 1px 2px rgba(0, 0, 0, 0.1);
      }
    }

    .arrowDot {
      opacity: 0;
    }

    &.fp-prev {
      transform: rotate(180deg);
      left: 40px;
      margin-left: -100px;
      transition: opacity ${(props) => props.theme.transitions.default},
        margin-left ${(props) => props.theme.transitions.default};

      @media ${breakpoints.tablet} {
        left: 17.5%;
      }
      @media ${breakpoints.desktop} {
        left: 16%;
      }
      @media ${breakpoints.xl} {
        left: 22%;
      }
    }
    &.fp-next {
      right: 40px;
      margin-right: -100px;
      transition: opacity ${(props) => props.theme.transitions.default},
        margin-right ${(props) => props.theme.transitions.default};

      @media ${breakpoints.tablet} {
        right: 17.5%;
      }
      @media ${breakpoints.desktop} {
        right: 16%;
      }
      @media ${breakpoints.xl} {
        right: 22%;
      }
    }
  }

  .scroll-down,
  .fp-slidesNav {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity ${(props) => props.theme.transitions.default},
      transform ${(props) => props.theme.transitions.default};
    transition-delay: 0.5s;
  }

  .fp-slidesNav {
    &.fp-bottom {
      bottom: 50px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    ul li a {
      span {
        background: ${(props) => props.theme.colors.bullet};
        width: 6px;
        height: 6px;
        margin: -3px 0 0 -3px;
        transition: background ${(props) => props.theme.transitions.default};

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          border: 1px solid rgba(${(props) => props.theme.rgbs.primary}, 1);
          border-radius: 50%;
          margin: -6px 0 0 -6px;
          opacity: 0;
          transition: opacity ${(props) => props.theme.transitions.default};
        }
      }

      &:hover,
      &.active,
      &.active:hover {
        span {
          width: 6px;
          height: 6px;
          margin: -3px 0 0 -3px;
          background: white;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
`

const FullPageSection = (props) => {
  return (
    <Section className="section fp-responsive-auto-height">
      {props.children}
    </Section>
  )
}

export default FullPageSection
