import React from 'react'
import styled from '@emotion/styled'
import { fluidRange } from 'polished'
import heading from '../styles/heading'
import breakpoints from '../styles/breakpoints'

const StyledHeading = styled.h2`
  ${heading}
  line-height: 1.1;

  ${props => fluidRange(
    {
      prop: 'font-size',
      fromSize: props.theme.fontSizes.jumbo.min,
      toSize: props.theme.fontSizes.jumbo.max,
    },
    '800px',
    '1000px',
  )}

  margin-bottom: 15px;  

  .bottom-border {
    @media ${breakpoints.tablet} {
      margin-top: 0;
    }     
  }
`
const HeadingJumbo = props => {

    let postBorder;
    let cssClass = "";
  
    if (props.showBorder) {
        postBorder = <div className="bottom-border" />;
    } 

    if (props.center) {
        cssClass += " center";
    }  
    

  return (
    <StyledHeading className={cssClass}>
      {props.children}
      {postBorder}
    </StyledHeading>
  )
}

export default HeadingJumbo
