import React, {Component} from 'react'
import styled from '@emotion/styled'
import Player from 'react-background-video-player'


const Video = styled(Player)`
  opacity: .4;
`;
class ResponsiveVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: 0,
            windowHeight: 0,
        }        
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
          window.addEventListener('resize', this.handleResize);
        }
      }
  
      componentWillMount() {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', this.handleResize);
        }
      }
  
      handleResize = () => {
        this.setState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        })
      };    

        render() {
            return (
                    <Video 
                        src={this.props.url}
                        containerWidth={this.state.windowWidth}
                        containerHeight={this.state.windowHeight}                        
                    />
            )            
        }




}

export default ResponsiveVideo