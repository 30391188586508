import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import FullPageSection from '../components/FullPageSection'
import SectionContainer from '../components/SectionContainer'
import ContentWrapper from '../components/ContentWrapper'
import HeadingJumbo from '../components/HeadingJumbo'
import PageBody from '../components/PageBody'
import LargeText from '../components/LargeText'

const TwoColSlide = (props) => {
  return (
    <FullPageSection>
      <SectionContainer>
        <ContentWrapper verticalText={props.verticalText}>
          <Grid fluid>
            <Row>
              <Col lg={12}>
                <HeadingJumbo showBorder>{props.title}</HeadingJumbo>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={5}>
                <LargeText>
                  {' '}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.largeText.childMarkdownRemark.html,
                    }}
                  />
                </LargeText>
              </Col>
              <Col md={5} mdOffset={1} lg={6} lgOffset={1}>
                <PageBody body={props.smallText} />
              </Col>
            </Row>
          </Grid>
        </ContentWrapper>
      </SectionContainer>
    </FullPageSection>
  )
}

export default TwoColSlide
