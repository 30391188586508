import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from '@emotion/react'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import { globalStyles } from '../styles/globalStyles.js'
import styled from '@emotion/styled'
import { Global } from '@emotion/react'

import config from '../utils/siteConfig'
import theme from '../styles/theme'

import DiamondLogo from '../images/logos/diamond.svg'
import SidebarMenu from './SidebarMenu'
import breakpoints from '../styles/breakpoints'
import Video from '../components/ResponsiveVideo'
import Vignette from '../components/Vignette'
import ContactForm from '../components/ContactForm'
import Div100vh from 'react-div-100vh'

const StyledDiamondLogo = styled(DiamondLogo)`
  width: 75px;
  position: fixed;
  top: 20px;
  left: 26px;
  right: 0;

  @media ${breakpoints.tablet} {
    width: 90px;
    top: 35px;
    margin: 0 auto;
  }
`

const VideoContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`
const HiddenForm = styled.div`
  display: none;
`

export default (props) => (
  <StaticQuery
    query={graphql`
      query Video {
        contentfulHomeScreen {
          backgroundVideo {
            file {
              url
            }
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider theme={theme}>
        <Div100vh className="siteRoot">
          <Helmet>
            <title>{config.siteTitle}</title>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href={favicon} />
          </Helmet>

          <VideoContainer>
            <Video url={data.contentfulHomeScreen.backgroundVideo.file.url} />
            <Vignette />
          </VideoContainer>

          <div className="siteContent">{props.children}</div>
          <SidebarMenu structure={props.structure}></SidebarMenu>

          <StyledDiamondLogo />

          <Global styles={globalStyles} />
          <HiddenForm>
            <ContactForm />
          </HiddenForm>
        </Div100vh>
      </ThemeProvider>
    )}
  />
)
